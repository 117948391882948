import { ComplexSearchRequestBuilder } from '@app/shared/search/builders/complex-search-request.builder';
import { FilterParameterBuilder } from '@app/shared/search/builders/filter-parameter.builder';
import { SearchRequestBuilder } from '@app/shared/search/builders/search-request.builder';
import {
  FilterParameter,
  FilterParameterOperator,
} from '@app/shared/search/interfaces/filter-parameter';
import {
  ComplexSearchRequest,
  SearchOperand,
} from '@app/shared/search/interfaces/search-request';
import { Reminder } from '../models/reminder';
import { RemindersStatusCodes } from '../models/reminders-status-codes';

export const handleReminderSearchRequests = (
  capturedId: string,
  filter?: string,
  searchData?: ComplexSearchRequest,
  showAll?: boolean,
): ComplexSearchRequest => {
  const complexSearchRequestBuilder = new ComplexSearchRequestBuilder();

  complexSearchRequestBuilder.addSearchRequest(
    new SearchRequestBuilder<Reminder>()
      .addNewFilterParameter(
        'capturedId',
        capturedId,
        FilterParameterOperator.Equals,
      )
      .build(),
  );

  const statusCodeFilterParameters: FilterParameter[] = [
    new FilterParameterBuilder<Reminder>()
      .setColumnName('statusCode')
      .setValue(RemindersStatusCodes.Captured)
      .setFilterOperator(FilterParameterOperator.Equals)
      .build(),
  ];

  if (showAll) {
    statusCodeFilterParameters.push(
      new FilterParameterBuilder<Reminder>()
        .setColumnName('statusCode')
        .setValue(RemindersStatusCodes.Complete)
        .setFilterOperator(FilterParameterOperator.Equals)
        .build(),
    );
  }

  complexSearchRequestBuilder.addSearchRequest(
    new SearchRequestBuilder<Reminder>()
      .addFilterParameters(statusCodeFilterParameters)
      .setOperand(SearchOperand.OR)
      .build(),
  );

  if (filter) {
    const filterSearchRequest: ComplexSearchRequest =
      new ComplexSearchRequestBuilder<Reminder>()
        .addSearchRequest(
          new SearchRequestBuilder<Reminder>()
            .addNewFilterParameter(
              'subject',
              filter,
              FilterParameterOperator.Contains,
            )
            .addNewFilterParameter(
              'description',
              filter,
              FilterParameterOperator.Contains,
            )
            .setOperand(SearchOperand.OR)
            .build(),
        )
        .build();

    complexSearchRequestBuilder.combine(filterSearchRequest);
  }

  if (searchData) {
    complexSearchRequestBuilder.combine(searchData);
  }

  return complexSearchRequestBuilder.build();
};
