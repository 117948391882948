<div class="card">
  <div
    [class.success]="showHeaderSuccess"
    [class.intermediateSuccess]="showHeaderIntermediateSuccess"
    [class.rejection]="showHeaderRejection"
    [ngStyle]="{ 'min-height.px': headerHeight }"
    class="header"
    [class.overdue]="showHeaderOverdue"
    [class.overdue2]="showReopenRequestHeader"
    [class.warning]="showWarningHeader"
    [class.error]="showErrorHeader">
    <div
      *ngIf="visibleActionButtons && visibleActionButtons.length > 0"
      class="actions">
      <app-icon-button
        *ngFor="let button of visibleActionButtons"
        [label]="button.name"
        [icon]="button.icon"
        (buttonClicked)="button.fn()"
        class="d-inline-block">
      </app-icon-button>
    </div>
    <div *ngIf="title" class="me-2">
      <h1>{{ title }}</h1>
    </div>
    <ng-container>
      <div class="column card-controls-divider">
        <ng-content select="[cardCustomHeader]"></ng-content>
      </div>
    </ng-container>
    <ng-container *ngFor="let buttonWithInfo of buttonsWithLabels">
      <div class="column card-controls-divider">
        <app-button-panel
          [buttons]="[buttonWithInfo.button]"></app-button-panel>
        <ng-container
          *ngFor="
            let info of buttonWithInfo.informationLabels;
            let i = index;
            let even = even
          ">
          <div *ngIf="even" class="follow-up-section-labels ms-3">
            <div class="follow-up-label">
              <small class="me-1">
                <b>{{ info.label }} </b>
              </small>
              <app-icon
                class="mt-1 ml-1"
                *ngIf="info.tooltip?.length > 0"
                [name]="info.iconName"
                [ngbTooltip]="info.tooltip"
                placement="{{ info.placement ?? 'bottom' }}"></app-icon>
            </div>
            <div
              *ngIf="buttonWithInfo.informationLabels.length >= i + 2"
              class="follow-up-label">
              <small class="me-1">
                <b>{{ buttonWithInfo.informationLabels[i + 1].label }}</b>
              </small>
              <app-icon
                class="mt-1 ml-1"
                *ngIf="
                  buttonWithInfo.informationLabels[i + 1].tooltip?.length > 0
                "
                [name]="buttonWithInfo.informationLabels[i + 1].iconName"
                [ngbTooltip]="buttonWithInfo.informationLabels[i + 1].tooltip"
                placement="{{
                  buttonWithInfo.informationLabels[i + 1].placement ?? 'bottom'
                }}"></app-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let info of informationLabels; let i = index; let even = even">
      <div *ngIf="even" class="follow-up-section-labels ms-3">
        <div class="follow-up-label">
          <small
            ><b>{{ info }} </b></small
          >
        </div>
        <div *ngIf="informationLabels.length >= i + 2" class="follow-up-label">
          <small
            ><b>{{ informationLabels[i + 1] }}</b></small
          >
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="visibleButtons && visibleButtons.length > 0"
      class="buttons ms-auto">
      <app-button-panel [buttons]="visibleButtons"></app-button-panel>
    </div>
    <div
      *ngIf="visibleDropdownOptions && visibleDropdownOptions.length > 0"
      class="buttons ms-auto">
      <div ngbDropdown class="d-inline-block" container="body">
        <button
          class="btn"
          [class.btn-primary]="
            !hasHighlightedDropdownOptions() &&
            !hasSecondaryHighlightedDropdownOptions()
          "
          [class.btn-success]="hasHighlightedDropdownOptions()"
          [class.btn-secondarySuccess]="
            hasSecondaryHighlightedDropdownOptions() &&
            !hasHighlightedDropdownOptions()
          "
          ngbDropdownToggle>
          Actions
        </button>
        <div class="ontop" ngbDropdownMenu>
          <ng-container *ngFor="let action of visibleDropdownOptions">
            <div
              [class.highlighted]="action.isHighlighted"
              [class.highlightedSecondary]="action.isSecondaryHighlighted">
              <button
                (click)="action.fn()"
                ngbDropdownItem
                [disabled]="!action.isEnabled">
                {{ action.name }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
