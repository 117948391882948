import { Injectable } from '@angular/core';
import { ApiError } from '@app/core/models/ErrorResponse';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { ToastService } from '@app/core/services/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { flatten } from 'ramda';
import { tap } from 'rxjs/operators';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';
import { ComplexSearchRequestBuilder } from '@app/shared/search/builders/complex-search-request.builder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationState } from '@app/authentication/state/authentication.state';
import { getUserDetails } from '@app/authentication/state/authentication.selectors';
import { RemindersService } from '../services/reminders.service';
import { Reminder } from '../models/reminder';
import * as RemindersSelectors from './reminders.selectors';
import { RemindersState } from './reminders.state';
import * as remindersActions from './reminders.actions';

@Injectable()
export class RemindersEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<RemindersState>,
    private authenticationState: Store<AuthenticationState>,
    private toastService: ToastService,
    private remindersService: RemindersService,
    private modalService: NgbModal,
  ) {}

  loadReminders = createEffect(() =>
    this.actions$.pipe(
      ofType(remindersActions.loadUserReminders),
      withLatestFrom(this.authenticationState.select(getUserDetails)),
      withLatestFrom(this.store$.select(RemindersSelectors.getUserReminders)),
      withLatestFrom(
        this.store$.select(RemindersSelectors.getShowAllRemindersFlag),
      ),
      map(flatten),
      switchMap(([, user, reminders, showAllRemindersFlag]) => {
        const complexSearch = new ComplexSearchRequestBuilder()
          .addSearchRequest(reminders.searchData)
          .build();

        return this.remindersService
          .loadUserReminders(
            user.username,
            reminders.offset,
            reminders.limit,
            reminders.sort,
            null,
            complexSearch,
            showAllRemindersFlag,
          )
          .pipe(
            map((response: PagedApiData<Reminder>) =>
              remindersActions.loadUserRemindersSuccess({ response }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not load user reminders - ${friendlyMessage}`,
              );
              return of(
                remindersActions.loadUserRemindersFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          );
      }),
    ),
  );

  markReminderComplete = createEffect(() =>
    this.actions$.pipe(
      ofType(remindersActions.markReminderComplete),
      switchMap(({ reminderId }) =>
        this.remindersService.markComplete(reminderId).pipe(
          tap(() => {
            this.toastService.showSuccess(
              `Successfully marked reminder as complete`,
            );
          }),
          mergeMap(() => [
            remindersActions.markReminderCompleteSuccess(),
            remindersActions.loadUserReminderNotifications(),
          ]),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Failed to mark reminder as complete - ${friendlyMessage}`,
            );
            return of(
              remindersActions.markReminderCompleteFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  markReminderCompleteSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(remindersActions.markReminderCompleteSuccess),
      withLatestFrom(this.store$.select(RemindersSelectors.getUserReminders)),
      switchMap(() => [
        remindersActions.clearReminders(),
        remindersActions.loadUserReminders({
          limit: defaultLimit,
          offset: defaultOffset,
        }),
      ]),
    ),
  );

  loadReminderNotificationCount = createEffect(() =>
    this.actions$.pipe(
      ofType(remindersActions.loadUserReminderNotifications),
      switchMap(() =>
        this.remindersService.getReminderNotificationsCount().pipe(
          map((response: any) =>
            remindersActions.loadUserReminderNotificationsSuccess({ response }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not load reminder notification count - ${friendlyMessage}`,
            );
            return of(
              remindersActions.loadUserReminderNotificationsFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  addReminder = createEffect(() =>
    this.actions$.pipe(
      ofType(remindersActions.addReminder),
      withLatestFrom(this.authenticationState.select(getUserDetails)),
      map(flatten),
      switchMap(([action, user]) =>
        this.remindersService
          .add(
            action.subject,
            action.actionDate,
            action.description,
            user.username,
            action.domainTypeId,
          )
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(`Successfully added a reminder`);
              this.modalService.dismissAll();
              return [
                remindersActions.addReminderSuccess({
                  subject: action.subject,
                }),
              ];
            }),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Failed to add a reminder - ${friendlyMessage}`,
              );
              return of(
                remindersActions.addReminderFailure({ error: friendlyMessage }),
              );
            }),
          ),
      ),
    ),
  );
}
