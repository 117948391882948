<app-modal title="Set Reminder">
  <form
    *ngIf="
      reminderAddform
    "
    [formGroup]="reminderAddform"
  >
    <div class="row">
      <div class="col-md-4 input-container">
        <app-date-input
          [parentForm]="reminderAddform"
          iconButton="calender"
          inputName="actionDate"
          labelName="Action Date"
        >
        </app-date-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-textarea-input
          [appendButton]="false"
          [inputName]="'description'"
          [labelName]="'Comment'"
          [maxLength]="700"
          [parentForm]="reminderAddform"
        >
        </app-textarea-input>
      </div>
    </div>
    <div class="mt-2">
      <button
        (click)="save()"
        [disabled]="
          !reminderAddform.valid || (isAddingReminder$|async)
        "
        class="btn btn-primary"
        type="submit"
      >
        {{ (isAddingReminder$|async) ? 'Saving...' : 'Save' }}
      </button>
      <button (click)="close()" class="btn btn-secondary ms-2">
        Cancel
      </button>
    </div>
  </form>
</app-modal>
