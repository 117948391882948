import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbModalModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RemindersService } from './services/reminders.service';
import { RemindersEffects } from './state/reminders.effects';
import { reducer } from './state/reminders.reducer';
import { RemindersListComponent } from './components/reminders-list/reminders-list.component';
import { RemindersSearchModalComponent } from './components/reminders-search-modal/reminders-search-modal.component';
import { RemindersSearchModalService } from './services/reminders-search-modal.service';
import { ReminderAddModalComponent } from './shells/reminder-add-modal/reminder-add-modal.component';
import { RemindersModalService } from './services/reminders-modal.service';
import { ViewReminderDescriptionModalComponent } from './components/view-reminder-description-modal/view-reminder-description-modal.component';
import { RemindersRoutingModule } from './reminders.routing.module';
import { RemindersFacade } from './state/reminders.facade';

@NgModule({
  declarations: [
    RemindersListComponent,
    RemindersSearchModalComponent,
    ReminderAddModalComponent,
    ViewReminderDescriptionModalComponent,
  ],
  exports: [RemindersListComponent],
  imports: [
    NgbProgressbarModule,
    RemindersRoutingModule,
    StoreModule.forFeature('reminders', reducer),
    EffectsModule.forFeature([RemindersEffects]),
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule,
    NgbNavModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbModalModule,
  ],
  providers: [
    RemindersFacade,
    RemindersService,
    RemindersSearchModalService,
    RemindersModalService,
  ],
})
export class RemindersModule {}
