<div class="table-container">
  <app-dashboard-table
    [showSearch]="showSearch"
    [searchEnabled]="searchEnabled"
    [stretchColumns]="true"
    [filterEnabled]="true"
    [showPills]="false"
    [tableDefinitions]="tableDefinitions"
    [shrinkHeight]="shrinkHeight"
    [nonClickableColumns]="nonClickableColumns"></app-dashboard-table>
</div>

<ng-template #actionCellTemplate let-row="row" let-rowIndex="rowIndex">
  <app-action-dropdown
    [actions]="reminderActions"
    [row]="row"></app-action-dropdown>
</ng-template>

<ng-template #showRemindersCheckBox>
  <div class="check-box form-inline me-5 custom-control custom-checkbox">
    <input
      class="custom-control-input"
      type="checkbox"
      id="showRemindersCheckBox"
      [checked]="showAllRemindersFlag$ | async"
      [(ngModel)]="isChecked"
      (change)="setAllRemindersFlag()" />
    <label class="custom-control-label" for="showRemindersCheckBox"
      >Show all Reminders</label
    >
  </div>
</ng-template>
