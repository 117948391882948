import { Injectable } from '@angular/core';
import { HttpService } from '@app/core';
import { ComplexSearchRequest } from '@app/shared/search/interfaces/search-request';
import { prepareComplexParameters } from '@app/core/http/http-resource-helper';
import { SortPropDir } from '@swimlane/ngx-datatable';
import { handleReminderSearchRequests } from './reminder-helpers';

@Injectable()
export class RemindersService {
  constructor(private http: HttpService) {}

  loadUserReminders = (
    capturedId: string,
    offset: number,
    limit: number,
    sortParams: SortPropDir,
    filter: string,
    searchData: ComplexSearchRequest,
    showAll: boolean = true,
  ) =>
    this.http.get('users/reminder', {
      ...prepareComplexParameters({
        offset,
        limit,
        sortParams,
        useSortBy: true,
        searchData: handleReminderSearchRequests(
          capturedId,
          filter,
          searchData,
          showAll,
        ),
      }),
    });

  markComplete(activityId: number) {
    return this.http.patch(`users/reminder/${activityId}`, {});
  }

  getReminderNotificationsCount() {
    return this.http.get('users/reminder/unread-reminder-count');
  }

  add = (
    subject: string,
    actionDate: Date,
    description: string,
    username: string,
    domainTypeId: number,
  ) =>
    this.http.post(`users/reminder`, {
      username,
      subject,
      actionDate,
      description,
      domainTypeId,
    });
}
