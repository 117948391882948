import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RemindersFacade } from '@app/user/reminders/state/reminders.facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReminderAddFormService } from '../services/reminder-add-form.service';

@Component({
  selector: 'app-reminder-add-modal',
  templateUrl: './reminder-add-modal.component.html',
  styleUrls: ['./reminder-add-modal.component.scss'],
  providers: [ReminderAddFormService],
})
export class ReminderAddModalComponent implements OnInit {
  @Input()
  subject: string | number;
  @Input()
  domainTypeId: number;
  @Input()
  loading = false;
  isAddingReminder$ = this.facade.isAddingReminder$;
  reminderAddform: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private facade: RemindersFacade,
    private reminderAddFormService: ReminderAddFormService,
  ) {
    this.reminderAddform = reminderAddFormService.reminderAddform;
  }

  ngOnInit(): void {}

  close() {
    this.reminderAddFormService.clear();
    this.activeModal.dismiss();
  }

  save() {
    this.facade.saveReminder(
      this.subject,
      this.reminderAddform.value.description,
      this.reminderAddform.value.actionDate,
      this.domainTypeId,
    );
  }
}
