import { ViewChild, TemplateRef } from '@angular/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TableAction } from '@app/shared/dashboard-table/models/table-action';
import { TableDefinition } from '@app/shared/dashboard-table/table-definitions/table-definition';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Reminder } from '@app/user/reminders/models/reminder';
import { RemindersSearchModalService } from '@app/user/reminders/services/reminders-search-modal.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemindersFacade } from '../../state/reminders.facade';
import { RemindersListTableDefinition } from './table-definitions/reminders-list-table-defination';

@Component({
  selector: 'app-reminders-list',
  templateUrl: './reminders-list.component.html',
  styleUrls: ['./reminders-list.component.scss'],
})
export class RemindersListComponent implements OnInit, OnDestroy {
  @Input()
  showSearch = true;
  searchEnabled = true;
  @Input()
  shrinkHeight: boolean;
  reminderActions: TableAction<Reminder>[] = [];
  tableDefinitions: TableDefinition<any>[];
  nonClickableColumns: string[] = ['action'];

  private columns: TableColumn[] = [];

  @ViewChild('actionCellTemplate', { static: true })
  actionCellTemplate: TemplateRef<any>;

  @ViewChild('showRemindersCheckBox', { static: true })
  headerTemplate: TemplateRef<any>;

  isChecked: boolean;
  showAllRemindersFlag$ = this.remindersFacade.showAllRemindersFlag$;

  constructor(
    private remindersFacade: RemindersFacade,
    private remindersSearchModalService: RemindersSearchModalService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.initializeTableDefinitions();
  }

  ngOnDestroy(): void {
    if (this.tableDefinitions) {
      this.tableDefinitions.forEach((td) => td.destroy());
    }

    this.remindersFacade.setShowAllStatusRemindersFlag(false);
  }

  initializeTableDefinitions() {
    const remindersListTableDefinition = new RemindersListTableDefinition(
      this.remindersFacade,
      this.remindersSearchModalService,
      this.router,
      this.modalService,
    );

    this.columns = remindersListTableDefinition.columns.map((col) => ({
      ...col,
      cellTemplate:
        col.prop === 'action' ? this.actionCellTemplate : col.cellTemplate,
    }));
    remindersListTableDefinition.columns = this.columns;
    remindersListTableDefinition.headerTemplate = this.headerTemplate;
    this.reminderActions = remindersListTableDefinition.actions;
    this.tableDefinitions = [remindersListTableDefinition];
  }

  setAllRemindersFlag() {
    this.remindersFacade.setShowAllStatusRemindersFlag(this.isChecked);
  }
}
