import { PagedApiData } from '@app/core/models/PagedApiData';
import { SearchRequest } from '@app/shared/search/interfaces/search-request';
import { createAction, props } from '@ngrx/store';
import { SortPropDir } from '@swimlane/ngx-datatable';
import { Reminder } from '../models/reminder';
import { UnreadReminderNotifications } from '../models/unread-reminder-notifications';

export const loadUserReminders = createAction(
  '[User : Reminders] - Load User Reminders',
  props<{ limit: number; offset: number }>(),
);

export const loadUserRemindersSuccess = createAction(
  '[User : Reminders] - Load User Reminders success',
  props<{ response: PagedApiData<Reminder> }>(),
);

export const loadUserRemindersFailure = createAction(
  '[User : Reminders] - Load User Reminders failure',
  props<{ error: string }>(),
);

export const sortReminders = createAction(
  '[User : Reminders] Sort Reminders',
  props<{ sort: SortPropDir }>(),
);

export const clearReminders = createAction(
  '[User : Reminders] Clear Reminders',
);

export const setRemindersSearchCriteria = createAction(
  '[User : Reminders] Set reminders search criteria',
  props<{ searchData: SearchRequest }>(),
);

export const markReminderComplete = createAction(
  '[User : Reminders] mark as complete',
  props<{ reminderId: number }>(),
);

export const markReminderCompleteSuccess = createAction(
  '[User : Reminders] mark as complete success',
);

export const markReminderCompleteFailure = createAction(
  '[User : Reminders] mark as complete failure',
  props<{ error: string }>(),
);

export const setShowAllRemindersFlag = createAction(
  '[User : Reminders] set show all reminders flag',
  props<{ isChecked: boolean }>(),
);

export const addReminder = createAction(
  '[User : Reminders] Add reminder',
  props<{
    subject: string;
    actionDate: Date;
    description: string;
    domainTypeId: number;
  }>(),
);

export const addReminderSuccess = createAction(
  '[User : Reminders] Add reminder sucess',
  props<{ subject: string }>(),
);

export const addReminderFailure = createAction(
  '[User : Reminders] Add reminder failure',
  props<{ error: string }>(),
);

export const loadUserReminderNotifications = createAction(
  '[User : Reminders] - Load User Reminder Notifications',
);

export const loadUserReminderNotificationsSuccess = createAction(
  '[User : Reminders] - Load User Reminder Notifications success',
  props<{ response: UnreadReminderNotifications }>(),
);

export const loadUserReminderNotificationsFailure = createAction(
  '[User : Reminders] - Load User Reminder Notifications failure',
  props<{ error: string }>(),
);
