import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent {
  @Input()
  icon: string;

  @Input()
  label: string;

  @Input()
  route: string;

  @Input()
  highlightRoute: string;

  @Input()
  expanded = false;

  @Input()
  openNewTab = false;

  @Output()
  clicked = new EventEmitter();

  private _notificationCount = 0;

  hasNotifications = false;

  @Input()
  set notificationCount(value: number) {
    this._notificationCount = value;
    this.hasNotifications = value > 0;
  }

  get notificationCount(): number {
    return this._notificationCount;
  }

  isActive = false;
}
