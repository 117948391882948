import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SpeedTestModule } from 'ng-speed-test';
import { CommunicationsModule } from '@app/communications/communications.module';
import { RemindersModule } from '@app/user/reminders/reminders.module';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { TimeoutModalComponent } from './components/timeout-modal/timeout-modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './shells/header/header.component';
import { NavigationBarComponent } from './shells/navigation-bar/navigation-bar.component';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    NgIdleKeepaliveModule.forRoot(),
    SpeedTestModule,
    CommunicationsModule,
    RemindersModule,
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
    NavigationBarComponent,
    NavigationItemComponent,
    TimeoutModalComponent,
    ToastComponent,
    NavigationButtonComponent,
  ],
  providers: [NgbActiveModal],
})
export class ShellModule {}
