import { initialPagedData, PagedApiData } from '@app/core/models/PagedApiData';
import { ApiData } from '@app/core/models/api-data';
import { Reminder } from '../models/reminder';
import { UnreadReminderNotifications } from '../models/unread-reminder-notifications';

export interface RemindersState {
  reminders: PagedApiData<Reminder>;
  showAllRemindersFlag: boolean;
  isAddingReminder: boolean;
  reminderNotifications: ApiData<UnreadReminderNotifications>;
}

export const initialRemindersState: RemindersState = {
  reminders: initialPagedData,
  showAllRemindersFlag: false,
  isAddingReminder: false,
  reminderNotifications: null,
};
