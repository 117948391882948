import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propEq } from 'ramda';
import { ImageViewerState } from './image-viewer.state';

const getImageViewerFeatureState =
  createFeatureSelector<ImageViewerState>('image-viewer');

export const getImageFile = createSelector(
  getImageViewerFeatureState,
  (state) => state.file.pdfImage,
);

export const getImageFileIsLoading = createSelector(
  getImageViewerFeatureState,
  (state) => state.file.isLoading,
);

export const getImageFileError = createSelector(
  getImageViewerFeatureState,
  (state) => state.file.error,
);

export const getShowImage = createSelector(
  getImageViewerFeatureState,
  (state) => {
    if (
      !state.file.isLoading &&
      state.file.pdfImage &&
      (state.selectedImage?.imageMetadata || state.uploadedImage?.pages)
    ) {
      return true;
    }
    return false;
  },
);

export const getShowConvertedImage = createSelector(
  getImageViewerFeatureState,
  (state) => {
    if (!state.file.isLoading && state.file.pdfImage) {
      return true;
    }
    return false;
  },
);

export const getImageMetadata = createSelector(
  getImageViewerFeatureState,
  (state) => state.selectedImage.imageMetadata,
);

export const getImageMetadataIsLoading = createSelector(
  getImageViewerFeatureState,
  (state) => state.selectedImage.isLoading,
);

export const getImageNumber = createSelector(
  getImageViewerFeatureState,
  (state) =>
    state.selectedImage.imageMetadata
      ? state.selectedImage.imageMetadata.imageNumber
      : null,
);

const getPdfViewerState = createSelector(
  getImageViewerFeatureState,
  (state) => state.pdfViewerState,
);

export const getCurrentPage = createSelector(
  getPdfViewerState,
  (state) => state.currentPage,
);

export const getNumberOfPages = createSelector(
  getPdfViewerState,
  (state) => state.numPages,
);

export const getZoomIncrements = createSelector(
  getPdfViewerState,
  (state) => state.totalZoomIncrements,
);

const defaultPageRotation = 0;

export const getUploadedImage = createSelector(
  getImageViewerFeatureState,
  (state) => state.uploadedImage,
);

export const getUploadedImagePages = createSelector(
  getUploadedImage,
  (state) => state?.pages ?? [],
);

export const getRotationDegreesForCurrentPage = createSelector(
  getImageMetadata,
  getCurrentPage,
  getUploadedImagePages,
  (selectedImage, currentPage, uploadImagePages) => {
    if (
      (!selectedImage || !selectedImage.pages) &&
      uploadImagePages.length === 0
    ) {
      return defaultPageRotation;
    }
    const isCurrentPage = propEq('pageNumber', currentPage);
    const pages =
      selectedImage && selectedImage.pages
        ? selectedImage.pages
        : uploadImagePages;
    const savedPageRotation = pages.find(isCurrentPage);
    return savedPageRotation
      ? savedPageRotation.rotationDegrees
      : defaultPageRotation;
  },
);

export const getShowViewer = createSelector(
  getImageViewerFeatureState,
  (state) => state.showViewer,
);

export const getImageFileIsRotating = createSelector(
  getImageViewerFeatureState,
  (state) => state.file.isRotating,
);

export const getImageIsSelected = createSelector(
  getImageViewerFeatureState,
  (state) => state.isImageSelected,
);

export const getImageIsSuccessfullyDownloaded = createSelector(
  getImageViewerFeatureState,
  (state) => state.successfullyDownloadedImage,
);

export const getAcceptedFileTypes = createSelector(
  getImageViewerFeatureState,
  (state) => state.acceptedFileTypes?.data,
);

export const isImageFileLoading = createSelector(
  getImageViewerFeatureState,
  (state) => state.file.isLoading,
);
