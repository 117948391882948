<div class="form-group">
  <label
    [for]="inputName"
    class="input-label"
    [class.success]="showSuccess"
    *ngIf="!hideLabel"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span></label
  >
  <div class="input-group mb-3">
    <input
      type="text"
      #typeahead
      class="form-control"
      placement="bottom-left"
      container="body"
      [formControlName]="inputName"
      [ngbTypeahead]="handleTypeahead"
      [inputFormatter]="formatInput"
      [resultFormatter]="parseResult"
      [focusFirst]="false"
      [showHint]="false"
      (focus)="focus$.next('')"
      autocomplete="off"
      (selectItem)="onItemSelected($event)"
      (blur)="setItem($event)"
      [placeholder]="placeholder"
      [resultTemplate]="useDataFormatter ? rt : null" />
    <app-copy-button
      *ngIf="canCopy"
      [copyItem]="getCopyItem()"
      [label]="copyLabel"
      [iconSize]="iconSize">
    </app-copy-button>
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()">
      <span class="btn-icon">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
    <div
      *ngIf="appendTextButton"
      class="input-group-append"
      (click)="onButtonClick()">
      <button class="btn btn-primary" [disabled]="!buttonEnabled" type="button">
        {{ buttonText }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="
      (errorMessage || inputValue.invalid) && inputValue.dirty;
      else label
    ">
    <app-error-label
      [message]="
        errorMessage ? errorMessage : 'This field is invalid'
      "></app-error-label>
  </ng-container>

  <ng-template #label>
    <app-info-label
      *ngIf="description && showDescription"
      [message]="description"></app-info-label>
  </ng-template>

  <ng-template #rt let-r="result" let-t="term">
    <p class="option-text">
      {{ formatResult(r) }}
    </p>
  </ng-template>
</div>
