import { stripTime } from '@app/core/helpers/date-strip-time';
import { DatatableRowStyler } from '@app/shared/table/styler/datatable-row-styler';
import { Reminder } from '../models/reminder';
import { RemindersStatusCodes } from '../models/reminders-status-codes';

export class RemindersRowStyler implements DatatableRowStyler {
  applyRowStyling(row: Reminder): any {
    const today = stripTime(new Date());
    const actionDate = stripTime(new Date(row.actionDate));

    return {
      'row-danger':
        actionDate <= today && row.statusCode === RemindersStatusCodes.Captured,
    };
  }
}
