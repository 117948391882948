import { Injectable } from '@angular/core';
import { errorless } from '@app/core/helpers/errorless';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemindersSearchModalComponent } from '../components/reminders-search-modal/reminders-search-modal.component';
import { Reminder } from '../models/reminder';
import { RemindersFacade } from '../state/reminders.facade';

@Injectable()
export class RemindersSearchModalService {
  constructor(
    private modalService: NgbModal,
    private remindersFacade: RemindersFacade,
  ) {}

  open = async ({ searchData }: PagedApiData<Reminder>): Promise<any> => {
    this.modalService.dismissAll();
    const modal = this.modalService.open(RemindersSearchModalComponent, {
      size: 'lg',
    });
    modal.componentInstance.searchData = searchData;

    const appliedFilters = await errorless(modal.result);
    if (appliedFilters) {
      this.remindersFacade.searchReminders(appliedFilters);
    } else {
      this.loadInitialData();
    }

    return appliedFilters;
  };

  loadInitialData() {
    this.remindersFacade.resetUserReminders();
    this.remindersFacade.loadUserReminders();
  }
}
