import { Component, Input, OnInit } from '@angular/core';
import { DateFilterField } from '@app/shared/filter/inputs/date.filter-field';
import { GenericFilterField } from '@app/shared/filter/inputs/filter-field';
import { TextFilterField } from '@app/shared/filter/inputs/text.filter-field';
import { SearchRequest } from '@app/shared/search/interfaces/search-request';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reminders-search-modal',
  templateUrl: './reminders-search-modal.component.html',
  styleUrls: ['./reminders-search-modal.component.scss'],
})
export class RemindersSearchModalComponent implements OnInit {
  @Input()
  searchData: SearchRequest;
  fields: GenericFilterField[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.setFilterFields();
  }

  setFilterFields() {
    this.fields = [
      new DateFilterField('actionDate', 'Action Date'),
      new TextFilterField('subject', 'Subject'),
      new TextFilterField('description', 'Description'),
    ];
  }

  doSearch(formValues: any) {
    this.activeModal.close(formValues);
  }
}
