import { Injectable } from '@angular/core';
import { errorless } from '@app/core/helpers/errorless';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReminderAddModalComponent } from '../shells/reminder-add-modal/reminder-add-modal.component';

@Injectable()
export class RemindersModalService {
  constructor(private modalService: NgbModal) {}

  async open(subject: string | number, domainTypeId: number) {
    const modal = this.modalService.open(ReminderAddModalComponent, {
      size: 'lg',
    });

    const componentInstance = modal.componentInstance;
    componentInstance.subject = subject;
    componentInstance.domainTypeId = domainTypeId;

    const results = await errorless(modal.result);
    return results;
  }
}
