import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ViewReminderDescriptionService } from '@app/user/reminders/components/view-reminder-description-modal/services/view-reminder-description-service';

@Component({
  selector: 'app-services',
  templateUrl: './view-reminder-description-modal.component.html',
  styleUrls: ['./view-reminder-description-modal.component.scss'],
  providers: [ViewReminderDescriptionService],
})
export class ViewReminderDescriptionModalComponent implements OnInit {
  form: UntypedFormGroup;

  @Input()
  titleLabel: string;
  @Input()
  descriptionLabel: string;

  @Input()
  set description(text: string) {
    this.formService.setText(text);
  }

  constructor(private formService: ViewReminderDescriptionService) {
    this.form = this.formService.form;
  }

  ngOnInit(): void {}
}
