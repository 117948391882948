<app-modal [title]="titleLabel">
  <div class="container">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <app-textarea-input
            [parentForm]="form"
            [inputName]="'description'"
            [labelName]="descriptionLabel"
            [appendButton]="false"
            [maxLength]="700"
            [readonly]="true"
          >
          </app-textarea-input>
        </div>
      </div>
    </form>
  </div>
</app-modal>
