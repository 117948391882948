import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { stripTime } from '@app/core/helpers/date-strip-time';
import * as rsaIdNumberParser from 'south-african-id-parser';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const nameValidator = Validators.pattern(/^[^0-9#$*&@()\[\]\\/?":;]*$/);
export const postalCodeValidator = Validators.pattern(/^[0-9]{4}$/);
export const caseInsensitiveStringValidator = Validators.pattern(/^[a-zA-Z]*$/);
export const upperCaseStringValidator = Validators.pattern(/^[A-Z]*$/);
export const lowerCaseStringValidator = Validators.pattern(/^[a-z]*$/);
export const idNumberValidator: ValidatorFn = (control) =>
  rsaIdNumberParser.parse(control.value).isValid
    ? null
    : {
        isValid: 'Invalid RSA ID',
      };
export const phoneNumberValidator = Validators.pattern(/^[0]{1}([0-9]{9})$/);
export const emailAddressValidator = Validators.pattern(
  /^[\w-\.]+@([\w-]+\.)+[\w-]+$/,
);

export const includesValidator =
  <T>(
    service: any,
    prop: string,
    fn: (type: T, value: any) => boolean,
  ): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (!service[prop] || !control.value) {
      return null;
    }

    const result = (service[prop] as []).find((p) => fn(p, control.value));
    return result
      ? null
      : {
          invalidOption: true,
        };
  };

export const dateInPast: ValidatorFn = (control) => {
  const today = stripTime(new Date());
  const selectedDate = stripTime(new Date(control.value));

  return selectedDate >= today
    ? null
    : {
        dateInPast: true,
      };
};

export const dateInFuture: ValidatorFn = (control) => {
  const today = stripTime(new Date());
  const selectedDate = stripTime(new Date(control.value));

  return selectedDate <= today
    ? null
    : {
        dateInFuture: true,
      };
};

export const arrayValidatorExtension =
  (validators: ValidatorFn[]): ValidatorFn =>
  (control: AbstractControl) => {
    let invalid = false;
    const controlValue = control.value;
    let controlValues: any[] = [];
    if (controlValue) {
      controlValues = controlValues.concat(controlValue);
    }
    controlValues.forEach((controlValue) => {
      const tempFormControl = new UntypedFormControl(controlValue, validators);
      tempFormControl.updateValueAndValidity();
      const validation = tempFormControl.errors;
      if (validation) {
        invalid = true;
      }
    });
    return invalid
      ? {
          invalidValues: true,
        }
      : null;
  };

export const minDateValidator =
  (minDate: Date): ValidatorFn =>
  (control: AbstractControl) => {
    const dateToString = control.value;
    if (!dateToString) {
      return null;
    }
    const dateToCheck = new Date(dateToString.toString().split('T')[0]);
    const minDateCheck = new Date(minDate.toString().split('T')[0]);
    return dateToCheck.getTime() === minDateCheck.getTime() ||
      dateToCheck.getTime() >= minDateCheck.getTime()
      ? null
      : { invalidDate: true };
  };

export const internationalPhoneNumberValidator =
  (countryCode: string): ValidatorFn =>
  (control: AbstractControl) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const regionCode =
      phoneNumberUtil.getRegionCodeForCountryCode(+countryCode);

    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value,
        regionCode,
      );
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) {}
    return !control.value || validNumber
      ? null
      : { wrongNumber: { value: control.value } };
  };
export const updateTreeValidity = (
  group: FormGroup | FormArray,
  emitEvent: boolean,
  onlySelf: boolean,
): void => {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.get(key);

    if (
      abstractControl instanceof FormGroup ||
      abstractControl instanceof FormArray
    ) {
      updateTreeValidity(abstractControl, emitEvent, onlySelf);
    } else {
      abstractControl.updateValueAndValidity({ emitEvent, onlySelf });
    }
  });
};
