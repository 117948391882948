import { Injectable } from '@angular/core';
import { AdvancedSearchService } from '@app/shared/search/providers/advanced-search.service';
import { select, Store } from '@ngrx/store';
import { SortPropDir } from '@swimlane/ngx-datatable';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';
import * as remindersActions from './reminders.actions';
import * as RemindersSelectors from './reminders.selectors';
import { RemindersState } from './reminders.state';

@Injectable()
export class RemindersFacade {
  userReminders$ = this.store$.pipe(
    select(RemindersSelectors.getUserReminders),
  );
  userReminderNotificationCount$ = this.store$.pipe(
    select(RemindersSelectors.getUserReminderNotifications),
  );
  userRemindersIsLoading$ = this.store$.pipe(
    select(RemindersSelectors.userRemindersIsLoading),
  );
  isAddingReminder$ = this.store$.pipe(
    select(RemindersSelectors.getIsAddingReminder),
  );
  constructor(
    private store$: Store<RemindersState>,
    private advancedSearch: AdvancedSearchService,
  ) {}

  loadUserReminders(
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.store$.dispatch(
      remindersActions.loadUserReminders({ limit, offset: offset + limit }),
    );
  }

  loadReminderNotifications() {
    this.store$.dispatch(remindersActions.loadUserReminderNotifications());
  }

  sortReminders(sort: SortPropDir) {
    this.store$.dispatch(remindersActions.sortReminders({ sort }));
    this.loadUserReminders();
  }

  resetUserReminders() {
    this.store$.dispatch(remindersActions.clearReminders());
  }

  searchReminders(unformattedSearchData: string | Record<string, unknown>) {
    const searchData = this.advancedSearch.getFormattedSearchData(
      unformattedSearchData,
      'subject',
    );

    if (searchData) {
      this.store$.dispatch(
        remindersActions.setRemindersSearchCriteria({ searchData }),
      );
    } else {
      this.resetUserReminders();
    }

    this.loadUserReminders();
  }

  markAsComplete(reminderId: number) {
    this.store$.dispatch(remindersActions.markReminderComplete({ reminderId }));
  }

  showAllRemindersFlag$ = this.store$.pipe(
    select(RemindersSelectors.getShowAllRemindersFlag),
  );

  setShowAllStatusRemindersFlag(isChecked: boolean) {
    this.resetUserReminders();
    this.store$.dispatch(
      remindersActions.setShowAllRemindersFlag({ isChecked }),
    );
    this.loadUserReminders();
  }

  saveReminder(
    subject: string | number,
    description: string,
    actionDate: Date,
    domainTypeId: number,
  ) {
    this.store$.dispatch(
      remindersActions.addReminder({
        subject: subject.toString(),
        actionDate,
        description,
        domainTypeId,
      }),
    );
  }
}
