import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';

@Component({
  selector: 'app-typeahead-label-input',
  templateUrl: './typeahead-label-input.component.html',
  styleUrls: ['./typeahead-label-input.component.scss'],
})
export class TypeaheadLabelInputComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  placeholder: string;

  showInput = false;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  data$ = new Observable<any[]>();

  @Input()
  inputProperty: string;

  @Input()
  inputDescription: string;

  @Input()
  iconButton: string;

  @Input()
  appendButton: boolean;

  @Input()
  iconSize = IconSizeEnum.Large;

  @Input()
  canCopy: boolean;

  @Input()
  showButtonIcon: boolean;

  @Input()
  buttonLabel: string;

  _copyLabel: string;

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  getCopyItem(): string {
    return this.label;
  }

  @Input()
  defaultData$: Observable<any[]> = of([]);

  @Output()
  filterChanged = new EventEmitter<string>();

  @Output()
  filterSelected = new EventEmitter();

  @Output()
  filterOpened = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.showInput = false;
  }

  onFilterChanged(filter: string) {
    this.filterChanged.emit(filter);
  }

  onFilterSelected(event: any) {
    this.showInput = false;
    this.filterSelected.emit(event);
  }

  onLabelClicked(event: any) {
    this.showInput = true;
    this.parentForm.get(this.inputName).setValue(this.label);
    this.filterOpened.emit();
  }

  onClose() {
    this.showInput = false;
  }
}
