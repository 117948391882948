import { Action, createReducer, on } from '@ngrx/store';
import * as RemindersActions from './reminders.actions';
import { RemindersState, initialRemindersState } from './reminders.state';

const remindersReducer = createReducer<RemindersState, Action>(
  initialRemindersState,
  on(RemindersActions.loadUserReminders, (state, { limit, offset }) => ({
    ...state,
    reminders: {
      ...state.reminders,
      limit,
      offset,
      isLoading: true,
    },
  })),
  on(RemindersActions.loadUserRemindersSuccess, (state, { response }) => ({
    ...state,
    reminders: {
      ...state.reminders,
      data: [...state.reminders.data, ...response.data],
      total: response.total,
      totalPages: response.totalPages,
      currentPage: response.currentPage,
      isLoading: false,
    },
  })),
  on(RemindersActions.loadUserRemindersFailure, (state, { error }) => ({
    ...state,
    reminders: {
      ...state.reminders,
      data: [],
      isLoading: false,
      error,
    },
  })),
  on(RemindersActions.sortReminders, (state, { sort }) => ({
    ...state,
    reminders: {
      ...state.reminders,
      data: [],
      sort,
    },
  })),
  on(RemindersActions.clearReminders, (state) => ({
    ...state,
    reminders: initialRemindersState.reminders,
  })),
  on(RemindersActions.setRemindersSearchCriteria, (state, { searchData }) => ({
    ...state,
    reminders: {
      ...state.reminders,
      data: [],
      searchData,
    },
  })),
  on(RemindersActions.setShowAllRemindersFlag, (state, { isChecked }) => ({
    ...state,
    showAllRemindersFlag: isChecked,
  })),

  on(RemindersActions.addReminder, (state) => ({
    ...state,
    isAddingReminder: true,
  })),
  on(RemindersActions.addReminderSuccess, (state) => ({
    ...state,
    isAddingReminder: false,
  })),
  on(RemindersActions.addReminderFailure, (state, { error }) => ({
    ...state,
    isAddingReminder: false,
  })),
  on(RemindersActions.loadUserReminderNotifications, (state) => ({
    ...state,
    reminderNotifications: {
      ...state.reminderNotifications,
      isLoading: true,
    },
  })),
  on(
    RemindersActions.loadUserReminderNotificationsSuccess,
    (state, { response }) => ({
      ...state,
      reminderNotifications: {
        data: response,
        isLoading: false,
        error: '',
      },
    }),
  ),
  on(
    RemindersActions.loadUserReminderNotificationsFailure,
    (state, { error }) => ({
      ...state,
      reminderNotifications: {
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),
);

export const reducer = (state: RemindersState, action: Action) =>
  remindersReducer(state, action);
