<form [formGroup]="dateRangeForm">
  <div [class.form-group]="labelName">
    <label *ngIf="labelName && !hideLabel" class="input-label"
      >{{ labelName }}
    </label>
    <div class="row">
      <div
        class="col-4"
        [ngClass]="{ 'col-6': !showResetButton, 'col-4': showResetButton }">
        <app-date-input
          [parentForm]="dateRangeForm"
          inputName="dateFrom"
          labelName="From"
          [iconButton]="'calender'"
          [minYear]="minYear"
          [flagAsRequired]="required"
          [hideLabel]="hideLabel">
        </app-date-input>
      </div>
      <div
        class="col-4"
        [ngClass]="{ 'col-6': !showResetButton, 'col-4': showResetButton }">
        <app-date-input
          [parentForm]="dateRangeForm"
          inputName="dateTo"
          labelName="To"
          [iconButton]="'calender'"
          [maxYear]="maxYear"
          [flagAsRequired]="required"
          [hideLabel]="hideLabel"
          class="flex-grow-1">
        </app-date-input>
      </div>
      <div *ngIf="showResetButton" class="col-3">
        <button (click)="reset()" class="btn btn-danger mt-4">Reset</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="dateRangeForm.invalid && dateRangeForm.touched">
          <app-error-label
            *ngFor="let error of dateRangeForm.errors | keyvalue"
            [message]="error.value"></app-error-label>
        </ng-container>
      </div>
    </div>
  </div>
</form>
