import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { AuthenticationFacade } from '@app/authentication/state/authentication.facade';
import { UpdateModalComponent } from '@app/core/components/update-modal/update-modal.component';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { HumanResourcesPermissionsEnum } from '@app/human-resources/shared/human-resources-permissions.enum';
import { DynamsoftWrapperComponent } from '@app/registry/images/components/dynamsoft-wrapper/dynamsoft-wrapper.component';
import { ImageUploadComponent } from '@app/registry/images/components/image-upload/image-upload.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FinancePermissionsEnum } from '@app/finance/models/finance-permissions.enum';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { RemindersFacade } from '@app/user/reminders/state/reminders.facade';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  activeClass = true;

  dashboardEnabled = this.appConfig.config.features.navButtons.dashboard;
  miStatsEnabled = this.appConfig.config.features.navButtons.miStats;
  imageApprovalEnabled =
    this.appConfig.config.features.navButtons.imageApproval;
  changeLogEnabled = this.appConfig.config.features.navButtons.changeLog;
  helpEnabled = this.appConfig.config.features.navButtons.help;
  policyListsEnabled = this.appConfig.config.features.navButtons.policyLists;
  financeEnabled = this.appConfig.config.features.navButtons.finance;
  supportEnabled = this.appConfig.config.features.navButtons.support;

  hangfireDashboardEnabled: boolean;
  hangfireDashboardUrl: string;
  humanResourceEnabled: boolean;

  remindersEnabled: boolean;
  uploadEnabled: boolean;
  scanEnabled: boolean;
  communicationEnabled: boolean;
  unreadWhatsAppNotifications$ =
    this.communicationsFacade.whatsappConversationNotifications$;
  communicationNotificationsEnabled =
    this.appConfig.config.features.notifications.communications;
  reminderNotifications$ = this.remindersFacade.userReminderNotificationCount$;

  constructor(
    private elementRef: ElementRef,
    private modalService: NgbModal,
    public authService: AuthenticationService,
    private authFacade: AuthenticationFacade,
    private appConfig: AppConfigService,
    private communicationsFacade: CommunicationsFacade,
    public remindersFacade: RemindersFacade,
  ) {}

  ngOnInit() {
    this.authFacade.isAuthenticated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.hangfireDashboardEnabled = this.isHangFireDashboardEnabled();
        this.hangfireDashboardUrl = this.appConfig.config.hangfireUrl;
        this.uploadEnabled = this.isUploadEnabled();
        this.scanEnabled = this.isScanEnabled();
        this.financeEnabled = this.isFinanceEnabled();
        this.humanResourceEnabled = this.isHumanResourcesEnabled();
        this.communicationEnabled = this.isCommunicationsEnabled();
        this.remindersEnabled = this.isRemindersVisible();
        if (this.remindersEnabled) {
          this.remindersFacade.loadReminderNotifications();
        }
      });
  }

  isFinanceEnabled(): boolean {
    return this.authService.hasPermission(
      FinancePermissionsEnum.AllocationsGet,
    );
  }

  isRemindersVisible(): boolean {
    if (this.authService.hasPermission('USER.REMINDER.GET')) {
      return true;
    }
  }

  isHumanResourcesEnabled(): boolean {
    return (
      this.appConfig.config.features.navButtons.humanResource &&
      this.authService.hasPermission(HumanResourcesPermissionsEnum.GetStaff)
    );
  }

  isHangFireDashboardEnabled(): boolean {
    return (
      this.appConfig.config.features.navButtons.hangfireDashboard &&
      this.authService.hasPermission('HANGFIRE.DASHBOARD')
    );
  }

  isUploadEnabled(): boolean {
    return (
      this.authService.hasPermission('REGISTRY.IMAGE.UPLOAD') &&
      this.appConfig.config.features.imageCreation.upload
    );
  }

  isScanEnabled(): boolean {
    return (
      this.authService.hasPermission('REGISTRY.IMAGE.SCAN') &&
      this.appConfig.config.features.imageCreation.scan
    );
  }

  toggleClass() {
    this.activeClass = !this.activeClass;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(ele: HTMLElement) {
    const thisEle = this.elementRef.nativeElement;
    if (!thisEle.contains(ele)) {
      this.activeClass = true;
    }
  }

  openModal() {
    this.modalService.open(UpdateModalComponent);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openUploadModal() {
    this.modalService.open(ImageUploadComponent);
  }

  openScanModal() {
    this.modalService.open(DynamsoftWrapperComponent);
  }

  private isCommunicationsEnabled() {
    return this.appConfig.config.features.communicationsFeatures
      .showWhatsAppMenu;
  }
}
