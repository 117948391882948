import { TableDefinition } from '@app/shared/dashboard-table/table-definitions/table-definition';
import { RemindersFacade } from '@app/user/reminders/state/reminders.facade';
import { Reminder } from '@app/user/reminders/models/reminder';
import { ButtonAction } from '@app/user/reminders/models/button-actions';
import { SortPropDir } from '@swimlane/ngx-datatable';
import { take } from 'rxjs/operators';
import { RemindersStatusCodes } from '@app/user/reminders/models/reminders-status-codes';
import { Router } from '@angular/router';
import { Injectable, Input } from '@angular/core';
import { RemindersRowStyler } from '@app/user/reminders/services/reminders-row-styler';
import { RemindersDomainTypes } from '@app/user/reminders/models/reminders-domain-types';
import { isValidClaimCode } from '@app/shared/helpers/claim-code-formatter';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewReminderDescriptionModalComponent } from '@app/user/reminders/components/view-reminder-description-modal/view-reminder-description-modal.component';
import { errorless } from '@app/core/helpers/errorless';
import { RemindersSearchModalService } from '../../../services/reminders-search-modal.service';

@Injectable()
export class RemindersListTableDefinition extends TableDefinition<Reminder> {
  @Input()
  public styleMethod: () => void;

  rowStyler = new RemindersRowStyler();

  constructor(
    private facade: RemindersFacade,
    private remindersSearchModalService: RemindersSearchModalService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
    this.title = 'Reminders';
    this.columns = [
      {
        name: '',
        prop: 'action',
        maxWidth: 45,
        sortable: false,
        frozenLeft: true,
      },
      { name: 'Subject', prop: 'subject', maxWidth: 400 },
      { name: 'Action Date', prop: 'actionDate', maxWidth: 150 },
      { name: 'Status Code', prop: 'statusCode', maxWidth: 150 },
      { name: 'Description', prop: 'description' },
    ];
    this.rows$ = this.facade.userReminders$;
    this.bindActions();
  }

  loadInitialData() {
    this.facade.resetUserReminders();
    this.facade.loadUserReminders();
  }

  sort(sort: SortPropDir): void {
    this.facade.sortReminders(sort);
  }

  select(reminder: Reminder): void {
    this.openReminderDescriptionModal(reminder);
  }

  async openReminderDescriptionModal(reminder: Reminder) {
    const modal = this.modalService.open(
      ViewReminderDescriptionModalComponent,
      {
        size: 'lg',
      },
    );
    modal.componentInstance.titleLabel = 'Reminder';
    modal.componentInstance.descriptionLabel = 'Description';
    modal.componentInstance.description = reminder.description;
    await errorless(modal.result);
  }

  loadMore({ offset, limit }: { offset: number; limit: number }): void {
    this.facade.loadUserReminders(offset, limit);
  }

  simpleSearch(searchString: string): void {
    this.facade.searchReminders(searchString);
  }

  advancedSearch(): void {
    this.rows$.pipe(take(1)).subscribe(this.remindersSearchModalService.open);
  }

  bindActions = () => {
    this.actions = [
      {
        label: 'View Reminder Source',
        action: ButtonAction.ViewReminder,
        click: (reminder) => this.navigateToSubject(reminder),
        isVisible: (reminder) =>
          isValidClaimCode(reminder.subject) || reminder.domainTypeId > 0,
      },
      {
        label: 'Mark As Complete',
        action: ButtonAction.MarkAsComplete,
        click: (reminder) => this.facade.markAsComplete(reminder.activityId),
        isVisible: (reminder: Reminder) =>
          reminder.statusCode === RemindersStatusCodes.Captured,
      },
    ];
  };

  registerSubscriptions(): void {
    this.subscriptions.push(
      this.facade.userRemindersIsLoading$.subscribe((loading) => {
        this.loadData(loading);
      }),
    );
  }

  fetchPolicyNumberFromString(inputString: string): string | null {
    const matches = inputString.match(/\d+$/);
    if (matches) {
      return matches[0];
    }
    return null;
  }

  navigateToSubject(item: Reminder): void {
    if (
      item.domainTypeId === RemindersDomainTypes.Claim ||
      isValidClaimCode(item.subject)
    ) {
      this.router.navigate([
        'claims-administration',
        'claim-overview',
        item.subject,
      ]);
    }

    if (item.domainTypeId === RemindersDomainTypes.Pension) {
      const beneficiaryId = item.subject;

      this.router.navigate(['pensions/pension-overview', item.claimId], {
        queryParams: { beneficiaryId },
      });
    }

    if (item.domainTypeId === RemindersDomainTypes.Underwriting) {
      this.router.navigate([
        'underwriting',
        'policy-overview',
        this.fetchPolicyNumberFromString(item.subject),
      ]);
    }
    if (item.domainTypeId === RemindersDomainTypes.NewUnderwriting) {
      this.router.navigate(['underwriting', 'policy', item.subject]);
    }
  }
}
