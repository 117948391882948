import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { dateInPast } from '@app/shared/helpers/form-validators';

@Injectable()
export class ReminderAddFormService {
  public reminderAddform: UntypedFormGroup;

  constructor(private formbuilder: UntypedFormBuilder) {
    const description = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
    const actionDate = new UntypedFormControl(new Date(), dateInPast);

    this.reminderAddform = this.formbuilder.group({
      description,
      actionDate,
    });
  }

  clear() {
    this.reminderAddform.setValue({
      description: '',
      actionDate: new Date(),
    });
  }
}
