import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Injectable()
export class ViewReminderDescriptionService {
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    const description = new UntypedFormControl('', [Validators.required]);

    this.form = this.fb.group({
      description,
    });
  }

  setText(text: string) {
    this.form.controls.description.setValue(text);
  }

  populateModal(): string {
    return this.form.get('description').value;
  }
}
