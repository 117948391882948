import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RemindersState } from './reminders.state';

const getRemindersFeatureState =
  createFeatureSelector<RemindersState>('reminders');

export const getUserReminders = createSelector(
  getRemindersFeatureState,
  (state) => state.reminders,
);

export const userRemindersIsLoading = createSelector(
  getUserReminders,
  (state) => state.isLoading,
);

export const getShowAllRemindersFlag = createSelector(
  getRemindersFeatureState,
  (state) => state.showAllRemindersFlag,
);

export const getIsAddingReminder = createSelector(
  getRemindersFeatureState,
  (state) => state.isAddingReminder,
);

export const getUserReminderNotifications = createSelector(
  getRemindersFeatureState,
  (state) => state.reminderNotifications.data,
);
